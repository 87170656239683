import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import uuid from "react-uuid"
import TitleStyled from "../components/styles/titleStyles"
import myInitObject from "../ultis/variable"
import EuProjectStyled from "../components/styles/EuProjectStyles"
import ShowHideItem from "../components/showHideItem"
import SEO from "../components/seo"

const IndexPage = ({ data, pageContext }) => {
  const { euProjectSingleton } = data.swapi

  return (
    <Layout pageContext={pageContext}>
      <SEO
        title={euProjectSingleton.metaTitle}
        description={euProjectSingleton.metaDescription}
        img={
          euProjectSingleton.metaImage ? euProjectSingleton.metaImage.path : ""
        }
        lang="pl"
      />
      <div className="container">
        <EuProjectStyled>
          <div className="logotype__container">
            {euProjectSingleton.logotype.map(logo => {
              return (
                <div className="logotype__item">
                  <img src={myInitObject.cockpitUrl + logo.value.logo.path} />
                </div>
              )
            })}
          </div>
          <TitleStyled textAlign="center" linePosition="center" color="#000000">
            <h2
              dangerouslySetInnerHTML={{ __html: euProjectSingleton.title }}
            ></h2>
          </TitleStyled>
          <div className="projects__container">
            {euProjectSingleton.project.map((project, index) => {
              return (
                <ShowHideItem
                  number={index}
                  key={uuid()}
                  title={project.value.title}
                  subTitle={project.value.subTitle}
                  description={project.value.description}
                />
              )
            })}
          </div>
        </EuProjectStyled>
      </div>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query euProjectPL {
    swapi {
      euProjectSingleton(lang: "pl") {
        metaTitle
        metaDescription
        metaImage {
          path
        }
        logotype {
          value
        }
        title
        project {
          value
        }
      }
    }
  }
`
